import React from "react";

import NavDropdown from "react-bootstrap/NavDropdown";
import { logFrClickEvent } from "utilities/firebase";

import useAuthentication from "hooks/useAuthentication";
import useTranslation from "hooks/useTranslation";
import decode from "jwt-decode";

import "./WacNav.css";

type WacNavProps = {};

const WacNav = (props: WacNavProps) => {
  const authentication = useAuthentication();
  const translation = useTranslation();

  return (
    <div className="WacNav">
      {authentication.token ? (
        <NavDropdown
          title={
            <span>
              <i className="bi bi-person-circle me-2" />
              {translation.t("account")}
            </span>
          }
          align="end"
        >
          <NavDropdown.Item
            href={authentication.createUrl()}
            onClick={() =>
              logFrClickEvent({
                name: "myAccount",
                persona: "watch-web",
              })
            }
          >
            {translation.t("myAccount")}
          </NavDropdown.Item>
          <NavDropdown.Item
            href={authentication.createUrl(
              `users/${
                authentication.token &&
                (decode(authentication.token) as any).cleengId
              }/edit`
            )}
            onClick={() =>
              logFrClickEvent({
                name: "updateAccount",
                persona: "watch-web",
              })
            }
          >
            {translation.t("updateAccount")}
          </NavDropdown.Item>
          <NavDropdown.Item
            href={authentication.createUrl("password/new")}
            onClick={() =>
              logFrClickEvent({
                name: "changePassword",
                persona: "watch-web",
              })
            }
          >
            {translation.t("changePassword")}
          </NavDropdown.Item>
          <NavDropdown.Item
            onClick={() => {
              logFrClickEvent({
                name: "signout",
                persona: "watch-web",
              });

              authentication.signout();
            }}
          >
            {translation.t("signout")}
          </NavDropdown.Item>
        </NavDropdown>
      ) : (
        <button className="WacNav-Signin" onClick={authentication.signin}>
          {translation.t("signin")}
        </button>
      )}
    </div>
  );
};

export default WacNav;
